import React, { useState } from 'react';
import {
  Table,
  InputNumber,
  Col,
  Modal,
  Button,
  Alert,
  Checkbox,
} from 'antd';
import AntForm, { AntSchema } from '@9troisquarts/ant-form.ant-form';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import compact from 'lodash/compact';
import { SearchOutlined } from '@ant-design/icons';
import columns from "./columns";
import { ApplicationType, Group, User } from '../../../../types';
import styles from './index.module.sass';
import useDocumentKeypress from '../../../../utils/useDocumentKeypress';
import { hasRole } from '../../../../utils/Authorization';
import { getApplication, readeableApps } from '../UserEdit/utils';
import useI18n from '../../../../utils/useI18n';
import SynchroStateHelp from './SynchroStateHelp';

interface IProps {
  users: User[];
  pagination: any;
  loading: boolean;
  search: { nameEmail?: string; applicationId?: string };
  setSearch: (search: { nameEmail: string; applicationId: string }) => void;
  setPagination: (pagination: { page: number; pageSize: number}) => void;
  applications: ApplicationType[];
  currentUser: User;
  onEdit: (id: string) => void;
  group: Group;
  onResendInvitations: (search) => void;
  editingRoles: string[];
  onPasswordEdit: (id) => void;
  part: string;
}

const searchSchema: (search: any, applications: ApplicationType[], onChange: (values) => void, t: any, currentUser: any, group: any) => AntSchema = (search, applications, onChange, t, currentUser, group) => {
  const schema: AntSchema = [[
    {
      name: "nameEmail",
      label: t("user.search.name_or_email"),
      input: {
        type: "string",
      },
      colProps: { md: 4 }
    },
    {
      name: "applicationId",
      label: t("user.search.application"),
      input: {
        type: "select",
        options: readeableApps(applications, currentUser, group).map(app => ({ value: app.id, label: app.name })),
        inputProps: {
          allowClear: true
        }
      },
      colProps: { md: 4 }
    },
    {
      name: "invitationState",
      label: t("user.search.invitation_state"),
      input: {
        type: "select",
        options: [{
          value: "all",
          label: t("user.search.states.all"),
        }, {
          value: "confirmed",
          label: t("user.search.states.confirmed"),
        }, {
          value: "waiting",
          label: t("user.search.states.waiting"),
        }, {
          value: "unsent",
          label: t("user.search.states.unsent"),
        }],
        inputProps: {
          allowClear: true
        }
      },
      colProps: { md: 3, xs: 24 }
    },
    // @ts-ignore
    search.invitationState === "waiting" && (
      <Col
        md={2}
      >
        <div className="ant-row ant-form-item">
          <Col className="ant-form-item-label"> { t("user.search.since")} </Col>
          <InputNumber
            onChange={value => onChange({ since: value })}
            value={search.since}
            addonAfter={<div>{t("words.days")}</div>}
          />
        </div>
      </Col>
    ),
    {
      name: "unsynced",
      label: ' ',
      input: {
        type: "checkbox",
        text: t("user.search.unsynced"),
      },
    }
  ],
  ];

  return schema;
};

const UserList: React.FC<IProps> = props => {
  const {
    applications,
    loading,
    users,
    setSearch,
    search,
    setPagination,
    pagination,
    currentUser,
    onEdit,
    onResendInvitations,
    group,
    editingRoles,
    onPasswordEdit,
    part,
  } = props;

  const { t } = useI18n();

  const [newSearch, setNewSearch] = useState<any>(search);
  const [synchroHelpOpen, setSynchroHelpOpen] = useState<boolean>(false);

  const onSubmit = () => {
    setNewSearch({ ...newSearch, searched: true });
    setSearch({ ...newSearch, searched: true });
  };

  useDocumentKeypress({
    callback: onSubmit,
    key: 'Enter',
    ctrlKey: false,
    enabled: true,
    deps: [newSearch],
  });

  const onChangeCheckbox = (checked, record) => {
    let newUserIds = [];
    if (checked) {
      newUserIds = uniq([...newSearch.userIds, record.id]);
    } else {
      newUserIds = without(newSearch.userIds, record.id);
    }
    setNewSearch({ ...newSearch, userIds: newUserIds })
  }

  const renderSelection = (checked, record) => {
    if (record.active) {
      return (
        <Checkbox
          checked={(newSearch.userIds || []).includes(record.id)}
          onChange={(e) => onChangeCheckbox(e.target.checked, record)}
        />
      );
    }
  };

  const confirm = () => {
    Modal.confirm({
      title: t("user.invitations.title"),
      content: (
        <div>
          {t("user.invitations.warning", { user_count: newSearch.userIds && newSearch.userIds.length > 0 && !newSearch.extendedSelection ? newSearch.userIds.length : pagination?.size })}
          <br />
          {t("user.invitations.warning_nb", { user_count: newSearch.userIds && newSearch.userIds.length > 0 && !newSearch.extendedSelection ? newSearch.userIds.length : pagination?.size })}
        </div>
      ),
      okText: t("words.true"),
      cancelText: t("words.cancel"),
      onOk: () => onResendInvitations(newSearch),
    });
  };

  const checkSelectedAll = (userIds) => {
    const dataSource = users.map(user => user.id);
    return difference(dataSource, userIds).length === 0;
  };

  const unavailableApps = () => {
    let appWithoutAccess: string[] = [];
    if (currentUser && currentUser.applicationSettings) {
      const appIds = applications.filter(app => app.active).map(app => app.id);
      const appSettingIds = currentUser.applicationSettings.map(appSetting => appSetting.applicationId);
      appWithoutAccess = without(appIds, ...appSettingIds);
    }

    if (currentUser && !hasRole(group, ["user_network_manager"])) {
      appWithoutAccess.push('network-manager');
    }

    return appWithoutAccess;
  };

  const onSynchroHelpClose = () => setSynchroHelpOpen(false);
  const onSynchroHelpOpen = () => setSynchroHelpOpen(true);

  return (
    <>
      <SynchroStateHelp
        open={synchroHelpOpen} onCancel={onSynchroHelpClose}
      />
      <div className={styles.searchForm} key="search-form">
        <AntForm
          object={newSearch}
          onSubmit={() => onSubmit()}
          onChange={(value) => setNewSearch({ ...newSearch, ...value, searched: false })}
          layout="vertical"
          submitText={t("words.search")}
          extraActions={(
            <>
              {((newSearch.userIds && newSearch.userIds.length > 0) || newSearch.extendedSelection) && (
                <Button type="primary" onClick={() => confirm()} loading={loading}>
                  {" "}
                  {t("user.invitations.resend")}{" "}
                </Button>
              )}
            </>
          )}
          submitButtonProps={{
            type: "primary",
            icon: <SearchOutlined />,
          }}
          actionsWrapperProps={{
            className: styles.searchButton
          }}
          schema={searchSchema(newSearch, applications, (value) => setNewSearch({ ...newSearch, ...value }), t, currentUser, group)}
        />
      </div>
      {newSearch.selectedAll && pagination.size > pagination.pageSize && (
        <Alert
          className={styles.alertUserSelection}
          message={(
            <div
              onClick={() => setNewSearch({ ...newSearch, extendedSelection: !newSearch.extendedSelection })}
            >
              {t(newSearch.extendedSelection ? "user.search.remove_selection" : "user.search.extend_selection", { user_count: pagination.size })}
            </div>
          )}
        />
      )}
      {unavailableApps().length > 0 && (
        <Alert
          className={styles.alertEditingWarning}
          showIcon
          type="info"
          message={(
            <div>
              {t("user.edition_warning")}&nbsp;
              <strong>
                {unavailableApps().map(appId => (
                  getApplication(applications, appId).name
                )).join(', ')}
              </strong>
            </div>
          )}
        />
      )}
      <Table
        key="user-table"
        dataSource={users || []}
        loading={loading}
        className={styles.table}
        rowKey={(record: User) => `user-${record.id}`}
        size="small"
        scroll={{ x: true }}
        rowSelection={hasRole(group, ["user_send_invitation"]) && newSearch.searched && ["waiting", "unsent"].includes(newSearch.invitationState) ? {
          onChange: (selectedRowKeys, selectedRows) => {
            const userIds = compact(selectedRows.map((user: User) => user.active ? user.id : undefined ));
            const selectedAll = checkSelectedAll(selectedRows.map(user => user.id));
            setNewSearch({
              ...newSearch,
              userIds,
              selectedAll,
              extendedSelection: newSearch.extendedSelection && selectedAll
            });
          },
          renderCell: false ? renderSelection : undefined
        } : undefined}
        onRow={(record: User) => ({
          onClick: () => record?.id ? onEdit(record.id) : undefined,
        })}
        columns={columns(applications, onEdit, currentUser, onResendInvitations, t, group, editingRoles, onPasswordEdit, part, { onSynchroHelpOpen })}
        pagination={{
          onChange: (page: number, pageSize: number) => {
            setNewSearch({ ...newSearch, selectedAll: false });
            setPagination({ page, pageSize });
          },
          total: pagination.total * pagination.pageSize,
          pageSizeOptions: ["15", "30", "50", "100"],
          showSizeChanger: true,
          pageSize: pagination.pageSize,
        }}
      />
      <Alert
        style={{ textAlign: "center" }}
        showIcon
        message={(
          <div className={styles.infoWrapper}>
            <div>
              { t('user.last_connexion_at_info.explanation')}
            </div>
            <div>
              { t('user.last_connexion_at_info.good_to_know')}
            </div>
          </div>
        )}
      />
    </>
  );
};

export default UserList;