import React, { useState } from 'react';

interface IProps {
    defaultColor ?: string | null;
    activeColor ?: string | null;
    defaultCursor ?: string | null;
    activeCursor ?: string | null;
    style ?: any;
    className ?: any;
    onClick ?: () => void | null;
}

const HighlightButton: React.FC<IProps> = props => {
    const {
        defaultColor,
        activeColor,
        defaultCursor,
        activeCursor,
        className,
        style,
        onClick,
    } = props;

    const [mouseOver, setMouseOver] = useState(false);

    return (
        <div
            onClick={onClick}
            className={className}
            onMouseEnter={()=>{setMouseOver(true);}}
            onMouseLeave={()=>{setMouseOver(false);}}
            style={{
                ...style,
                backgroundColor: mouseOver?activeColor:defaultColor,
                cursor:  mouseOver?activeCursor:defaultCursor,
                
            }}
        >

            {props.children}

        </div>
    );
};

HighlightButton.defaultProps = {
    defaultColor: "transparent",
    activeColor: "#eae9e9",
    defaultCursor: "default",
    activeCursor : "pointer",
}

export default HighlightButton;