import React from 'react';
import {
  Col,
  Row,
} from "antd";
import {
  TeamOutlined,
  CommentOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useIntl } from "react-intl";

import HighlightButton from "../../../../components/HighlightButton";
import { hasRole } from "../../../../utils/Authorization";
import { Group, ApplicationType, Tenant } from '../../../../types';
import styles from './index.module.sass';
import { logoForApp } from "../../../User/components/UserEdit/utils";
import DefaultAppPicto from "../../../../../../../public/default_app_picto.svg";
import { isDevelopment } from '../../../../../packs/config';

interface IProps {
  application: ApplicationType;
  userGroup?: Group | undefined;
  /** if `true` disable all the possible actions on the cards */
  readOnly?: boolean;
  onEdit: (application: ApplicationType) => void;
  /** bind `applicationEdit` modal to the edit action on the card (ie: pass application data to the function used to open a modal 
   * in order to display and edit application data of that card) */
  tenant: Tenant;
}

const appUrl = (application) => {
  if (!application) {
    return "";
  }

  let url = `https://${application.url.replace('.api', '')}`;
  if (isDevelopment) {
    url = url.replace("https", "http").replace(".com", ".test").replace(".staging", "");
  }

  return url;
};

const AppCard: React.FC<IProps> = props => {
  const {
    application,
    userGroup,
    readOnly,
    onEdit,
    tenant,
  } = props;

  const intl = useIntl();

  return (
    <HighlightButton
      className={styles.clickableZone}
      activeCursor={application.active ? "pointer" : "not-allowed"}
    >
      <div style={{ position: "relative" }}>
        <Col
          style={{ pointerEvents: application.active ? "auto" : "none" }}
          className={styles.cardBlock}
          onClick={() => { window.open(appUrl(application)); }}
        >
          <div className={styles.cardBody} style={{ backgroundColor: application.active ? "" : "#f1e0e0" }}>

            <div className={styles.cardBlockImageWrapper} style={{ marginBottom: "20px" }}>
              <img
                className={styles.cardImage}
                alt={`logo-for-${application.applicationType}-${application.id}`}
                src={application.templateImageUrl || DefaultAppPicto}
              />
            </div>

            <div>
              <div className={styles.logoWrapper}>
                <div className={styles.logoContainer}>
                  <img
                    width={30}
                    alt={`logo-for-${logoForApp[`${application.applicationType}`]['compact']}`}
                    src={logoForApp[`${application.applicationType}`]['compact']}
                    style={{ marginRight: "1px", marginBottom: "2px" }}
                  />
                </div>
              </div>
            </div>

            <span className={styles.blockTitle} style={{ paddingLeft: "13px", color: tenant.primaryColor }}>
              {application.name}
            </span>
            <Row style={{ width: "100%" }}>
              <Col span={8}>
                {
                  application.active ? (
                    <div>
                      <CheckOutlined className={styles.iconLabel} />
                      <span className={styles.blockInfo}>
                        ({ intl.formatMessage({ id: "appCard.active" }) })
                      </span>

                    </div>
                  ) : (
                    <div>
                      <CloseOutlined className={styles.iconLabel} />

                      <span className={styles.blockInfo}>
                        ({ intl.formatMessage({ id: "appCard.inactive" }) })
                      </span>

                    </div>
                  )
                }
              </Col>
              <Col span={8}>
                <div>
                  <TeamOutlined className={styles.iconLabel} />
                  <span className={styles.blockLabel}> {application["userCount"]} </span>
                </div>
              </Col>
              { false && (
                <Col span={5}>
                  <div>
                    <CommentOutlined className={styles.iconLabel} />
                    <span className={styles.blockLabel}> 0 </span>
                  </div>
                </Col>
              )}

            </Row>

          </div>

        </Col>

        { !readOnly && hasRole(userGroup, ["portal_create", "portal_edit"]) && (
          <div
            onClick={() => onEdit(application)}
            className={styles.editButtonWrapper}
          >
            <EditOutlined
            />
          </div>
        )}

      </div>

    </HighlightButton>
  );
};

AppCard.defaultProps = {
  readOnly: true,
  userGroup: undefined,
};

export default AppCard;