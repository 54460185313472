import React from 'react';
import { ColumnsType } from "antd/lib/table";
import { Menu, Popover, Space, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ClockCircleFilled,
  MailOutlined,
  LockOutlined,
  WarningOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { FormattedDate } from 'react-intl';
import styles from "./index.module.sass";
import MiniNwLogo from "../../../../../../../public/nw_manager.svg";
import { hasRole } from '../../../../utils/Authorization';
import useI18n from '../../../../utils/useI18n';
import { getApplication, logoForApp, readeableApps } from "../UserEdit/utils";
import spyIcon from "../../../../../../../public/user-secret-light.svg";
import ISVG from 'react-inlinesvg';
import ContextMenu from '../../../../components/ContextMenu';

const UserAppStatus = props => {
  const {
    active,
    lastSignInAt,
    roles,
    isNm = false,
  } = props;
  const { t } = useI18n();
  return (
    <Tooltip
      title={!lastSignInAt ? undefined
        : (
          <>
            {roles && (
              <ul style={{ marginBottom: 0, listStyle: "none", paddingLeft: "15px" }}>
                {roles.map(role => (
                  <li key={role}>
                    {isNm ? role : t(`activerecord.attributes.user.application_setting.roles.${role}`)}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
    >
      <div className={styles.userAppStatutsWrapper}>
        {active && (
          <CheckOutlined style={{ color: "green", marginRight: "5px", fontSize: "20px" }} />
        )}
        {!active && (
          <CloseOutlined style={{ color: "red", marginRight: "5px", fontSize: "20px" }} />
        )}
        {lastSignInAt && (
          <div>
            <div>{t("user.last_sign_in_at")}</div>
            <FormattedDate value={lastSignInAt} />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

const SyncStatus = ({ record, apps }) => {
  const { t } = useI18n();

  let state = "synced"
  let index = 0

  record.applicationSettings.forEach(app => {
    if (app.synced === null) {
      state = "syncing"
      return
    }
    if (app.synced === false) {
      state = "partial_synced"
      index += 1
    }
  })

  const syncHash = {
    synced: {
      icon: <CheckCircleOutlined style={{ fontSize: "20px", color: "green" }} />,
      message: t('synchronization.all_app_synced')
    },
    syncing: {
      icon: <QuestionCircleOutlined style={{ fontSize: "20px", color: "orange" }} />,
      message: t('synchronization.app_is_syncing')
    },
    not_synced: {
      icon: <WarningOutlined style={{ fontSize: "20px", color: "red" }} />,
      message: t('synchronization.no_app_synced')
    },
    partial_synced: {
      icon: <WarningOutlined style={{ fontSize: "20px", color: "orange" }} />,
      message: (
        <div>
          {t('synchronization.some_app_not_synced')}
          <ul style={{ marginBottom: 0 }}>
            {record.applicationSettings.filter(app => !app.synced).map(app => (
              <li
                key={app.applicationId}
              >
                {getApplication(apps, app.applicationId)?.name}
              </li>
            ))}
          </ul>
        </div>
      )
    }
  }

  if (index === record.applicationSettings.length) state = "not_synced"

  return (
    <Popover
      title={ t('sentences.synchronization_state') }
      content={syncHash[state].message}
    >
      {syncHash[state].icon}
    </Popover>
  )
}

const columns = (applications, onEdit, currentUser, onResendInvitations, t, group, editingRoles, onPasswordEdit, part, { onSynchroHelpOpen }): ColumnsType => {

  const apps = readeableApps(applications, currentUser, group)
  const columns = [
    {
      title: t("activerecord.attributes.user.lastname"),
      dataIndex: "lastname",
    },
    {
      title: t("activerecord.attributes.user.firstname"),
      dataIndex: "firstname",
    },
    {
      title: t("activerecord.attributes.user.email"),
      dataIndex: "email",
    },
    (currentUser?.email?.includes('cxfirst.com') || currentUser?.email?.includes('qualimetrie.com')) ? {
      title: (
        <div style={{ width: 150 }}>
          <span className="dotted-underlined cursor-pointer" onClick={onSynchroHelpOpen}>
            Statut Synchro
          </span>
        </div>
      ),
      width: 150,
      align: "center",
      render: (text, record) => (
        <SyncStatus record={record} apps={apps} />
      ),
    } : undefined,
    {
      title: (
        <div style={{ width: 100 }}>
          Actif
        </div>
      ),
      dataIndex: "active",
      align: "center",
      width: 100,
      render: (text, record) => (
        <div
          className={styles.activeWrapper}
        >
          {record?.active && (
            <CheckCircleOutlined style={{ color: "green", fontSize: "20px" }} />
          )}
          {!record.active && (
            <CloseOutlined style={{ color: "red", fontSize: "20px" }} />
          )}
          {(!record.provider || record.provider === "email") && (
            <>
              {!record.invitationAccepted && record.invitationSentAt && (
                <Tooltip
                  title={(
                    <div className={styles.tooltipWrapper}>
                      <div>
                        {t("user.invitation_sent_at")}
                      </div>
                      <FormattedDate value={record.invitationSentAt} />
                    </div>
                  )}
                >
                  <ClockCircleFilled
                    style={{ color: "#b2b2b2" }}
                    className={styles.circleOutlined}
                  />
                </Tooltip>
              )}
              {!record.invitationAccepted && !record.invitationSentAt && (
                <ClockCircleFilled
                  style={{ color: "#b2b2b2" }}
                  className={styles.circleOutlined}
                />
              )}
            </>
          )}
        </div>
      )
    },
  ].filter(Boolean);

  const appCol = apps.map(app => (
    {
      title: (
        <Tooltip title={app.name}>
          <img width={30} alt={`logo-for-${logoForApp[app.applicationType]['compact']}`} src={logoForApp[app.applicationType]['compact']} />
          &nbsp;{app.name.substring(0, 10)}
        </Tooltip>
      ),
      align: "center",
      width: 150,
      render: (text, record) => {
        const currentApp = record.applicationSettings[record.applicationSettings.findIndex(appSetting => app.id === appSetting.applicationId)];
        if (!currentApp) return " - ";
        return (
          <UserAppStatus
            active={currentApp.active}
            lastSignInAt={currentApp.lastSignInAt}
            roles={currentApp.roles}
          />
        );
      }
    }
  ));

  if (currentUser?.networkManagerAccess) {
    appCol.push({
      title: (<Tooltip title="Network Manager"><img width={30} alt="mini-nw-logo" src={MiniNwLogo} /></Tooltip>),
      align: "center",
      width: 50,
      render: (text, record) => {
        if (!record?.networkManagerAccess) return ' - ';
        return (
          <UserAppStatus
            active={record.networkManagerAccess}
            lastSignInAt={record?.lastSignInAt}
            roles={[group.name]}
            isNm
          />
        );
      }
    });
  }

  const menuHandler = callback => ({ domEvent: e }) => {
    e.preventDefault();
    e.stopPropagation();
    callback();
  }

  return [...columns, ...appCol, {
    title: "",
    fixed: 'right',
    align: "right",
    render: (text, { id, invitationAccepted, active }) => (
      <Space size={4}>
        <ContextMenu
          size="default"
          trigger={['click', 'hover']}
          // @ts-ignore
          overlay={(
            <Menu
            >
              {(part === "qualimetrie" ? hasRole(group, ['user_qualimetrie']) && hasRole(group, editingRoles) : hasRole(group, editingRoles)) && (
                <Menu.Item
                  key="edit"
                  icon={<EditOutlined />}
                  onClick={menuHandler(() => onEdit(id))}
                >
                  {t("words.edit")}
                </Menu.Item>
              )}
              {hasRole(group, ["user_update_password"]) && active && (
                <Menu.Item
                  key="editPassword"
                  icon={<LockOutlined />}
                  onClick={menuHandler(() => onPasswordEdit({ id }))}
                >
                  {t("sentences.edit_password")}
                </Menu.Item>
              )}
              {hasRole(group, ["user_send_invitation"]) && !invitationAccepted && active && (
                <Menu.Item
                  key="resend-invitation"
                  icon={<MailOutlined />}
                  onClick={menuHandler(() => onResendInvitations({ id }))}
                >
                  {t("sentences.resend_invitation")}
                </Menu.Item>
              )}
              {(currentUser.hasAllAccess || hasRole(group, ['impersonate_user'])) && (
                <Menu.Item
                  key="impersonate"
                  icon={<ISVG src={spyIcon} width={15} />}
                  onClick={menuHandler(() => { window.location.href = (`/users/${id}/impersonate_user`); })}
                >
                  {t("sentences.impersonate_user")}
                </Menu.Item>
              )}
            </Menu>
          )}
        />
      </Space>
    )
  }];
};

export default columns;