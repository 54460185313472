import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useQuery } from 'react-query';
import {
  Row,
  Col,
  PageHeader,
  Dropdown,
  Menu,
  Button
} from "antd";
import {
  ShopOutlined,
  TeamOutlined,
  DownOutlined,

} from '@ant-design/icons';
import {
  ApplicationType,
  Group,
  Tenant
} from "../../types";

import useI18n from "../../utils/useI18n";
import ApplicationEdit from "../Application/components/ApplicationEdit";
import styles from "./index.module.sass";
import SiteEditContainer from "../Site/components/SiteEditContainer";
import { hasRole } from "../../utils/Authorization";
import { useCurrentUser } from "../User/components/UserEdit/utils";
import UserEditContainer from "../User/components/UserEdit/UserEditContainer";
import AppCardList from "./components/AppCardList";
import useApplication, { getApplications } from "./hooks/useApplication";

interface IProps {
  group: Group;
  onUserEdit: () => void;
  onSiteEdit: () => void;
  siteCount: number;
  userCount: number;
  fetchHierarchyTrees: () => void;
  tenant: Tenant;
}

const Home: React.FC<IProps> = props => {
  const {
    group,
    onUserEdit,
    onSiteEdit,
    siteCount,
    userCount,
    fetchHierarchyTrees,
    tenant,
  } = props;
  const [editingApplication, setEditingApplication] = useState<ApplicationType | undefined>(undefined);
  const {
    onSave,
    errors,
    availableAddons,
    loading: saveLoading,
  } = useApplication(editingApplication);

  const onEdit = (application) => setEditingApplication(application);

  useEffect(() => fetchHierarchyTrees(), []);

  const onCancel = () => setEditingApplication(undefined);

  const handleSave = (application) => onSave(application, {
    onSuccess: () => setEditingApplication(undefined)
  });

  const { isLoading: loading, data: { applications = [] } = {} } = useQuery(['applications'], getApplications, {
    refetchInterval: false,
  });

  const { t } = useI18n();

  const editingRoles = ["user_qualimetrie", "user_network_manager"];
  applications?.forEach(app => editingRoles.push(`user_application_${app.id}`));

  const currentUser = useCurrentUser();
  if (!currentUser) return null;

  return (
    <Row
      className={styles.dashboardWrapper}
      gutter={16}
      style={{
        margin: "16px 0",
      }}
    >
      <div className={styles.headerWrapper}>
        <PageHeader title={<span style={{ fontSize: 30 }}> {t('home.dashboard')}</span>}  />
        { hasRole(group, [...editingRoles, "site_create"]) && (
        <div className={styles.actionWrapper}>
          <Dropdown
            trigger={["hover"]}
            overlay={(
              <Menu>
                {hasRole(group, editingRoles) && (
                  <Menu.Item key="user-edit">
                    <UserEditContainer />
                    <a onClick={() => onUserEdit()}>
                      {t('user.new')}
                    </a>
                  </Menu.Item>
                )}
                {hasRole(group, ["site_create"]) && (
                  <Menu.Item key="site-edit">
                    <SiteEditContainer />
                    <a onClick={() => onSiteEdit()}>
                      {t('sites.add_site')}
                    </a>
                  </Menu.Item>
                )}
                { hasRole(group, ["portal_create"]) && (
                  <Menu.Item key="portal-edit">
                    <a onClick={() => onEdit({ })}>
                      {t('applications.add_portal')}
                    </a>
                  </Menu.Item>
                )}
              </Menu>
            )}
          >
            <Button
              type="primary"
            >
              {t("home.quick_access")}
              <DownOutlined style={{ marginLeft: "10px" }} />
            </Button>
          </Dropdown>
        </div>
        )}
      </div>

      <Col style={{ flexDirection: 'column' }}>
        <Row style={{ paddingLeft: "10px" }} gutter={16}>
          <Col
            className={styles.block}
          >
            <div>
              <ShopOutlined style={{ fontSize: "30px" }} />
              <span className={styles.blockTitle}>Sites</span>
              {`${siteCount}`}
            </div>
          </Col>
          <Col className={styles.block}>
            <div>
              <TeamOutlined style={{ fontSize: "30px" }} />
              <span className={styles.blockTitle}>Utilisateurs</span>
              {`${userCount}`}
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={16} style={{ flexDirection: 'column', paddingLeft: "18px", marginTop: "20px" }}>
        <div className={styles.headerWrapper}>
          <h3 style={{ color: "#BEBEBE" }}>
            {t("home.portal_list")}
          </h3>
        </div>

        <AppCardList
          appList={applications}
          onEdit={onEdit}
          userGroup={group}
          readOnly={false}
          tenant={tenant}
        />

      </Col>

      {!!editingApplication && (
        <ApplicationEdit
          application={editingApplication}
          loading={saveLoading}
          open
          onClose={onCancel}
          errors={errors}
          onSubmit={handleSave}
          availableAddons={availableAddons}
        />
      )}

    </Row>

  );
};

const mapStateToProps = state => ({
  group: state.group.current,
  applicationOpen: state.applications.open,
  errors: state.applications.errors,
  tenant: state.tenants.current,
  applications: state.applications.list,
  hierarchyTrees: state.hierarchyTrees.list,
});

const mapDispatchToProps = dispatch => ({
  fetchHierarchyTrees: () => dispatch({ type: 'hierarchyTree/fetch' }),
  onEdit: (id) => dispatch({ type: 'application/setEdit', payload: id }),
  onCancel: () => {
    dispatch({ type: 'application/setOpen', payload: false });
    dispatch({ type: 'application/setCurrent', payload: {} });
    dispatch({ type: 'application/setErrors', payload: {} });
  },
  onSave: (params) => dispatch({ type: "application/saveApplication", payload: params }),
  onDelete: (id) => dispatch({ type: "application/deleteApplication", payload: id }),
  onUserEdit: (id?: string) => dispatch({ type: "user/show", payload: { id } }),
  onSiteEdit: (id) => dispatch({ type: 'site/setEdit', payload: id }),
  onSiteEditCancel: () => {
    dispatch({ type: 'site/setOpen', payload: false });
    dispatch({ type: 'site/setCurrent', payload: {} });
    dispatch({ type: 'site/setErrors', payload: {} });
  },
  onSiteSubmit: (params) => {
    dispatch({ type: 'site/saveSite', payload: params });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);